import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const communication = {
  header: {
    title: 'communicationHeaderTitle',
    subTitle: 'communicationHeaderSubTitle',
    image: 'communication.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'COMM_get_started',
      id: 'sign_up_communication'
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'COMM_request_demo',
      id: 'request_demo_communication'
    }
  },
  content: [
    {
      title: 'communicationContentChanelTitle',
      subTitle: 'communicationContentChanelSubTitle',
      image: 'communication-connect.png',
    },
    {
      title: 'communicationContentRobotTitle',
      subTitle: 'communicationContentRobotSubTitle',
      image: 'communication-automate.png',
    },
    {
      title: 'communicationContentBisLogicTitle',
      subTitle: 'communicationContentBisLogicSubTitle',
      image: 'communication-logic.png',
    },
    // {
    //   title: 'communicationContentBotTitle',
    //   subTitle: 'communicationContentBotSubTitle',
    //   image: 'robot.png',
    // },
  ],
  demo: {
    event: 'COMM_digital_core_start'
  }
};


const Communication = (props) => (
  <Layout {...props}>
    <Landing
      data={communication}
      page={'communication'}
    />
  </Layout>
);


export default withIntl(Communication);

